import React from 'react';
import { Row, Col } from "react-bootstrap";
import styles from './Content.module.css';
import PageContent from '../PageContent/PageContent'
import SideBar from '../SideBar/SideBar.js'
import { Zoom } from 'react-slideshow-image';
import BGImages from '../../../../../bg-images';
import { useMediaQuery } from 'react-responsive';

const Content = () => {

    const images = BGImages.map((item) => { return item.image });

    const properties = {
        arrows: false,
        scale: 0.4,
        duration: 5000
    }

    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });

    return (
        <div className="mainContent" as={"main"}>
            {isDesktopOrLaptop &&
                <Row className={styles.content}>
                    <Col sx="1" lg="3">
                        <SideBar />
                    </Col>
                    <Col sx="11" lg="9">
                        <PageContent />
                    </Col>
                </Row>}
            {isTabletOrMobile &&
                <Row className={styles.content}>
                    <Col sx="1" lg="3"></Col>
                    <Col sx="11" lg="9">
                        <PageContent style={{ padding: "auto", display: "flex", justifyContent: "center", flexDirection: "column" }} />
                    </Col>
                </Row>}
            {isDesktopOrLaptop &&
                <Zoom {...properties} className={styles.photo}>
                    {images.map((pict, index) =>
                        <img key={index} style={{ width: "100%" }} src={pict} alt="Kościół w Jedlance" />
                    )}
                </Zoom>
            }
        </div>
    );
}


export default Content;