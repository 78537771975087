import React from 'react';
import {NavLink, Card, CardImg} from 'react-bootstrap';
import {Link} from 'react-router-dom';


const NewsListItem = ({image,title,slug}) => {
    return(
        <>
            <Card style={{marginTop:"10px", marginLeft:"20px"}}>
                {image ? <CardImg  variant="top" src={image.url}/> : <p>Brak zdjęcia</p>}            
            <Card body>
                <NavLink 
                    as={Link} 
                    to={{ pathname: "/aktualnosc/"+slug, 
                            slug: slug }}
                >
                    {title}
                </NavLink> 
            </Card>                    
        </Card>
        </>
    );
}

export default NewsListItem;