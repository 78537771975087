import React from 'react';

const AddressMap=()=>{
    return (
        <div className="google-map-code">
          <iframe title="ParafiaLocation" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2459.6318492250457!2d22.10892711598272!3d51.940668379708676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4718ac8bd66f093f%3A0x7d34f0730132c03e!2sParafia%20rzymskokatolicka%20Chrystusa%20Kr%C3%B3la!5e0!3m2!1spl!2spl!4v1656877192188!5m2!1spl!2spl" width="100%" height="600" style={{border:0, borderRadius:"20px", marginTop:"10px"}} allowFullScreen="" loading="lazy"></iframe>
        </div>
    );
 }

 export default AddressMap;