const BGImages = [
    {
        id:1,
        image: '/Assets/bg-images/stone-texture.jpeg'
    },
    {
        id:2,
        image: '/Assets/bg-images/white-marble-texture-background-abstract-marble-texture-natural-patterns-for-design.jpeg'
    }
]

export default BGImages;