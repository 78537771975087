import React from 'react';
import { Nav, Navbar, NavbarBrand, NavItem, NavLink, NavDropdown } from "react-bootstrap";
import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse';
import NavbarToggle from 'react-bootstrap/esm/NavbarToggle';
import { Link } from "react-router-dom";
import LOGO from '../../../../../logos';
import { useMediaQuery } from 'react-responsive';

const AppNavbar = () => {
    const images = LOGO.map((item) => { return item.image });

    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });


    return (
        <>
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                <NavbarBrand className='col-6 col-md-4' as={Link} to="/">
                    {isTabletOrMobile && <img style={{ width: "250px" }} src={images[3]} alt="" />}
                    {isDesktopOrLaptop && <img style={{ width: "350px" }} src={images[3]} alt="" />}
                </NavbarBrand>
                <NavbarToggle aria-controls="responsive-navbar-nav" />
                <NavbarCollapse id="responsive-navbar-nav">
                    <Nav className="col-8 col-sm-8 col-md-10" style={{ alignItems: "center", verticalAlign: "center" }} variant="pils">
                        <NavItem href="/aktualnosci">
                            <NavLink as={Link} to="/aktualnosci" eventKey="Aktualności" title="Aktualności">
                                Aktualności
                            </NavLink>
                        </NavItem>
                        <NavItem href="/intencje">
                            <NavLink as={Link} to="/intencje" eventKey="Intencje" title="Intencje">
                                Intencje
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink as={Link} to={{ pathname: "https://www.facebook.com/parafiajedlanka" }} target="_blank" eventKey="Facebook" title="Facebook Parafii">
                                {"Facebook"}
                            </NavLink>
                        </NavItem>
                        {/* <NavItem href="/parafia">
                            <NavLink as={Link} to="/parafia" eventKey="Parafia" title="Parafia">
                                Parafia
                            </NavLink>
                        </NavItem> */}
                        <NavDropdown title="Parafia" id='basic-nav-dropdown' >
                            <NavDropdown.Item as={Link} to="/parafia" eventKey="Parafia" title="Parafia">
                                Parafia
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/sod" eventKey="standards" title="Standardy Ochrony Dzieci">
                                Standardy Ochrony Dzieci
                            </NavDropdown.Item>
                        </NavDropdown>
                        <NavItem href="/live">
                            <NavLink as={Link} to="/live" eventKey="live" >
                                Transmisje Mszy Świętych
                            </NavLink>
                        </NavItem>
                        <NavItem href="/galerie">
                            <NavLink as={Link} to="/galerie" eventKey="Galeria" title="Galeria">
                                Galerie
                            </NavLink>
                        </NavItem>
                        <NavItem href="/kontakt">
                            <NavLink as={Link} to="/kontakt" eventKey="Kontakt" title="Kontakt">
                                Kontakt
                            </NavLink>
                        </NavItem>
                    </Nav>
                </NavbarCollapse>

            </Navbar>
        </>
    );
}

export default AppNavbar;