import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive';
import { useQuery } from 'urql';
import { Document, Page, pdfjs } from 'react-pdf';

const SodQuery = `
{
  mainInfos(where: {category_in: "SOD"}) {
    content {
      html
    }
    category
    file {
      fileName
      url
    }
    photos {
      url
      fileName
    }
    title
    addDate
  }
}
`;

const Sod = () => {
    const [result] = useQuery({ query: SodQuery })
    const { data, fetching, err } = result;

    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    pdfjs.GlobalWorkerOptions.workerSrc =
        `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    if (fetching) return <p>Pobieram informacje o SOD...</p>
    if (err) return <p>O nie... {err.message}</p>

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setPageNumber(1);
    }

    const previousPage = () => setPageNumber(pageNumber - 1);
    const nextPage = () => setPageNumber(pageNumber + 1);

    return (
        <>
            {isDesktopOrLaptop &&
                <div style={{ marginTop: "10px", width: "100%", backgroundColor: "white", borderRadius: "11px" }}>
                    <div key={data.mainInfos[0]?.slug} style={{ marginTop: "10px", width: "100%", display: "flex", justifyContent: "center" }}>                        <>
                        <div key={data.mainInfos[0]?.slug}>
                            <h1>{data.mainInfos[0]?.title}</h1>
                            <div style={{ width: "100%" }}>
                                <Document
                                    file={data.mainInfos[0]?.file[0].url}
                                    options={{ workerSrc: "/pdf.worker.js" }}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                >
                                    <Page pageNumber={pageNumber}
                                        width="900"
                                    />
                                </Document>
                            </div>
                            <div>
                                <p>
                                    Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
                                </p>
                                <button className="button" type="button" disabled={pageNumber <= 1} onClick={previousPage}>
                                    Poprzednia
                                </button>
                                <button
                                    className="button"
                                    type="button"
                                    disabled={pageNumber >= numPages}
                                    onClick={nextPage}
                                >
                                    Następna
                                </button>
                            </div>
                        </div>
                    </>
                    </div>
                </div>
            }
            {isTabletOrMobile &&
                <div style={{ marginTop: "10px", width: "100%", backgroundColor: "white", borderRadius: "11px", marginLeft: "10px" }}>
                    <div key={data.mainInfos[0]?.slug} style={{ marginTop: "10px", width: "100%", display: "flex", justifyContent: "center" }}>
                        <>
                            <div key={data.mainInfos[0]?.slug}>
                                <h1>{data.mainInfos[0]?.title}</h1>
                                <div style={{ width: "100%" }}>
                                    <Document
                                        file={data.mainInfos[0]?.file[0].url}
                                        options={{ workerSrc: "/pdf.worker.js" }}
                                        onLoadSuccess={onDocumentLoadSuccess}
                                    >
                                        <Page pageNumber={pageNumber}
                                            width="300"
                                        />
                                    </Document>
                                </div>
                                <div style={{ justifyContent: "center" }}>
                                    <p>
                                        Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
                                    </p>
                                    <button className="button" type="button" disabled={pageNumber <= 1} onClick={previousPage}>
                                        Poprzednia
                                    </button>
                                    <button
                                        className="button"
                                        type="button"
                                        disabled={pageNumber >= numPages}
                                        onClick={nextPage}
                                    >
                                        Następna
                                    </button>
                                </div>
                            </div>
                        </>
                    </div>
                </div>
            }
        </>
    );
}

export default Sod