import React from 'react';
import { NavLink, Card, CardImg } from 'react-bootstrap';
import { Link } from "react-router-dom";

const ParafiaItem = ({logo, title, slug}) => {
    return(
    <Card style={{marginTop:"10px", marginLeft:"20px"}}>
        {logo ? <CardImg style={{width:"100%", height:"100%"}} variant='top' src={logo} /> : <p>Brak zdjęcia</p>}        
        <Card body>
            <NavLink
                as={Link}
                to={{ pathname: "/oParafii/"+slug, 
                            slug: slug }}
            >            
                {title}
            </NavLink>
        </Card>
    </Card>
    )
}

export default ParafiaItem;