import React from 'react';
import { NavLink, Card} from 'react-bootstrap';
import { Link } from "react-router-dom";

const IntentionItem = ({title, slug}) => {
    return(
        <Card style={{marginTop:"10px", marginLeft:"20px"}}>
            <Card body>
                <NavLink
                    as={Link}
                    to={{ pathname: "/intencjeOd/"+slug, 
                                slug: slug }}
                >            
                    {title}
                </NavLink>
            </Card>
        </Card>
    )
}

export default IntentionItem;