import React from 'react';
import AddressMap from './AddressMap';

const Contact = () => {
    return (
        <>
            <div style={{ marginTop: "10px", width: "100%", backgroundColor: "white", borderRadius: "20px" }}>
                <div style={{ marginTop: "10px", display: "flex", alignItems: "center", flexDirection: "column" }}>
                    <h3>Parafia Rzymskokatolicka pw. Chrystusa Króla w Jedlance</h3>
                    <h5>ul. Ks. Leszkiewicza 4, 21-450 Jedlanka</h5>
                    <h5>tel. 25 640 58 28</h5>
                    <h5><a href="mailto:parafia.jedlanka1928@gmail.com">parafia.jedlanka1928@gmail.com</a></h5>
                </div>
                <div className="border-top my-2"></div>
                <div style={{ marginTop: "10px", display: "flex", alignItems: "center", flexDirection: "column" }}>
                    <h3>Możesz złożyć dobrowolną ofiarę na funkcjonowanie naszej parafii i na utrzymanie świątyni. </h3>
                    <p>Możesz to zrobić bezgotówkowo, na różne sposoby: </p>
                    <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: "10px" }}>
                        <div style={{ marginLeft: "10px" }}>
                            <p><b>1 sposób: </b></p>
                            <p>Zrób przelew na konto bankowe parafii </p>
                            <p>Parafia Rzymsko - Katolicka</p>
                            <p>p.w. Chrystusa Króla w Jedlance</p>
                            <p>ul. Ks. Leszkiewicza 4, 21-450 Jedlanka</p>
                            <p>BS Trzebieszów, o. Stoczek Łukowski</p>
                            <p><b>85 9206 1022 6812 2063 3000 0010</b></p>
                            <p><b>Tytuł: Darowizna na cele kultu religijnego</b></p>
                        </div>
                        <div style={{ marginRight: "10px" }}>
                            <p><b>2 sposób: </b></p>
                            <p>Zrób stałą dyspozycję przelewu określonej kwoty na cele kultu religijnego.</p>
                        </div>
                    </div>
                    Bóg zapłać za wszystkie, nawet najdrobniejsze, ofiary na rzecz naszej parafii.
                </div>
                <div className="border-top my-2"></div>
                <AddressMap style={{ marginTop: "10px" }} />

            </div>
        </>
    );
}

export default Contact;