 import React from 'react';
 import ParafiaItem from './ParafiaItem';
 import { useQuery, gql } from 'urql';
 
 const AllAbout = gql`
 {
    oParafiis(orderBy: dodano_DESC){
      tytul
      slug
      logo{
        url
      }
    }
  }
 `;

 const ParafiaItemsList = () => {
    const [result] = useQuery({query: AllAbout})
    const { data, fetching, err } = result;

    
    if(fetching) return <p>Pobieram informacje o parafii...</p>
    if(err) return <p>O nie... {err.Message}</p> 
    
    return(
        <>
            {data?.oParafi?.map((props) => (
                <ParafiaItem
                    key={props.slug}
                    title={props.title}
                    logo={props.logo?.url}
                    slug={props.slug}
                    />
            ))}
        </>
    )
 }

 export default ParafiaItemsList;