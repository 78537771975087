import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createClient, Provider } from 'urql';
import { CookiesProvider } from "react-cookie";
import ReactGA from "react-ga";
// import env from 'react-dotenv';

const client = createClient({
  url: process.env.REACT_APP_GRAPH_ENDPOINT,
});

const trackingCode = process.env.REACT_APP_TRACKING_CODE;

ReactDOM.render(
  <React.StrictMode>
    <Provider value={client}>
      <CookiesProvider>
        <App>
          {
            ReactGA.initialize(trackingCode)
          }
        </App>
      </CookiesProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
