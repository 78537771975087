import React from 'react';
import ReactPannellum from "react-pannellum";
import Panorama from '../../../../Panorama360';
import { useMediaQuery } from 'react-responsive';

const images = Panorama.map((item) => {return item.image});

const Home = () => {
    const config = {
        autoLoad: true,
        compass: true
    }

    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });

    return(
        <div style={{marginTop:"10px", width:"100%", backgroundColor:"white", borderRadius:"20px"}}>
            <h1 style={{display:"flex", justifyContent:"center"}}> Sprawdź jak wygląda nasz kościół w 360&deg;</h1>  
            <div style={{dispaly:"flex", justifyContent:"center", marginTop:"10px", marginBottom:"10px"}} >
                <h3 style={{display:"flex", justifyContent:"center"}}>Chwyć zdjęcie i przesuwaj </h3>      
                {
                    isDesktopOrLaptop &&    
                    <ReactPannellum  
                        id="1"
                        sceneId="firstScene"
                        imageSource={images[0]}   
                        config={config} 
                        style={{width:"100%", height:"600px", borderRadius:"20px"}}    
                    />  
                }
                {
                    isTabletOrMobile &&    
                    <ReactPannellum  
                        id="1"
                        sceneId="firstScene"
                        imageSource={images[0]}   
                        config={config} 
                        style={{width:"100%", height:"300px", borderRadius:"20px"}}    
                    />  
                }
            </div>            
        </div>            
    )
}

export default Home;