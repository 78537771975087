import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import { SidebarS, SidebarList } from './Styles';
import { Timeline }from 'react-twitter-widgets';
import SideBarItem from './SideBarItem';

const SideBar = () => {

    return(
        <Container>
            <Row>
                <Col sx="12">
                    <SidebarS>
                        <SidebarList>            
                            <Container style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                                    <h4 style={{fontWeight:"bold", color:"#a38a17"}}>Msze Święte</h4>
                                    <span>W dni powszednie:<br/></span><span><b>07:00 lub 18:00 </b></span><span>Od października do Wielkanocy:</span><span><b>07:00 lub 16:00</b></span><br/>
                                    <span style={{alignItems:"center"}}>W Niedzielę:<br/></span><span style={{alignItems:"center"}}><b>07:00, 09:00, 11:00</b></span>
                            </Container>      
                            <Container style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                                    <h4 style={{fontWeight:"bold", color:"#a38a17"}}>Spowiedź</h4>                                    
                                    <span style={{fontWeight:"bold"}}>30 minut przed każdą Mszą Świętą</span>                            
                            </Container>  
                            <Container style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                                    <h4 style={{fontWeight:"bold", color:"#a38a17"}}>Kancelaria Parafialna</h4>
                                    <span>Kancelaria czynna: </span>
                                    <span><b>Bezpośrednio po każdej Mszy Świętej</b></span>
                                    <span><b>z wyjątkiem Niedziel i uroczystości</b></span><br/>
                                    <span><b>W wyjątkowych sytuacjach</b></span>
                                    <span><b>kontakt telefoniczny z</b></span>
                                    <span><b>ks. proboszczem:</b></span>
                                    <span>668-156-555</span>
                                    
                            </Container>
                            <div className="border-top my-2"></div>
                                <SideBarItem iframe='<iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fparafiajedlanka%2F&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="100%" height="500" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>' />                 
                            <div className="border-top my-2"></div>
                                <SideBarItem iframe='<iframe src="https://opoka.org.pl/liturgia_iframe" scrolling="yes" style="height: 500px; width: 100%" height="auto" frameborder="0"></iframe >'
                                             title="Liturgia dnia" />
                            <div className="border-top my-2"></div>
                            <div>
                                <SideBarItem  iframe='<iframe src="https://open.spotify.com/embed-podcast/show/2F5tOicGHhqpSH9NuKTqLz" width="100%" height="250" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>'
                                                title="Modlitwa w Drodze" />
                                
                            </div>   
                            <div className="border-top my-2"></div>
                            <div>
                                <h3 style={{display:'flex', justifyContent:'center'}}>Tweety Papieża</h3>
                                <Timeline 
                                    dataSource={{
                                        sourceType: 'profile',
                                        screenName: 'Pontifex_pl'
                                    }}    
                                    options={{
                                        width: '100%',
                                        height: '200',
                                        theme: 'dark',
                                        chrome: 'noheader, nofooter',
                                        lang: 'pl'
                                    }}  
                                    renderError={err => 
                                        "Wystąpił problem z ładowaniem tweetów papieża"
                                    }   
                                />                   
                            </div>
                            <div className="border-top my-2"></div>
                        </SidebarList>
                    </SidebarS>
                </Col>
            </Row>
        </Container>
    );
}

export default SideBar;