import React, { useState, useEffect } from 'react';
import NewsListItem from './NewsListItem';
import { gql, useQuery } from 'urql';
import ReactPaginate from 'react-paginate';
import styles from "./News.module.css";
import { useMediaQuery } from 'react-responsive';

const NewsQuery = gql`
    {
        aktualnoscis(orderBy: dodano_DESC){
            tytul
            dodano
            createdBy {
                name
            }
            slug
            thumbnails(last:1) {
                url(
                    transformation:{
                      image:{ resize: {width:250, height:250} }
                    }
                  )
            }
        }
    }
`;

const Items = ({currentItems}) => {
    return(
        <>
        {currentItems && currentItems.map((item) => (
                <div className={styles.items} key={item.slug} style={{alignItems:"center", marginTop:"10px"}}>
                    <NewsListItem key={item.slug} image={item.thumbnails[0]} title={item.tytul} slug={item.slug}/>        
                </div>
            ))
        }
        </>
        );        
}

const PaginatedItems = ({ itemsPerPage }) => {
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [result] = useQuery({query: NewsQuery});
    const { data, fetching, err } = result;

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(data?.aktualnoscis.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(data?.aktualnoscis.length / itemsPerPage));
    }, [itemOffset, data?.aktualnoscis, itemsPerPage]);

    const handlePageClick = (event) => {
        const newOffset = event.selected * itemsPerPage % data.aktualnoscis.length;
        setItemOffset(newOffset);
    };

    if(fetching) return <p>Pobieram aktualności...</p>;
    if(err) return <p>O nie... {err.message}</p>;

    return (
        <>
          <Items currentItems={currentItems} />
          <ReactPaginate
            nextLabel="następna >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="< poprzednia"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </>
      );
}

const NewsList = () => {

    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });

    return(
        <>
        { isDesktopOrLaptop && <PaginatedItems itemsPerPage={21} /> }
        { isTabletOrMobile && <PaginatedItems itemsPerPage={9} /> }
        </> 
    );
}

export default NewsList;