import React, { useState, useEffect } from 'react';
import { gql, useQuery } from "urql"; 
import GalleryItem from "./GalleryItem";
import ReactPaginate from 'react-paginate';
import styles from "./Gallery.module.css";
import { useMediaQuery } from 'react-responsive';


const GalleriesQuery = gql`
    {
        galeries(orderBy: dodano_DESC){
        tytul
        thumbnails {
            url(
                transformation:{
                  image:{ resize: {width:200, height:200} }
                }
              )
        }
        slug
        }
    } 
`;

const Items = ({currentItems}) => {
    return(
        <>
            {currentItems && currentItems.map((item) => (
                <div className={styles.items}>
                    <GalleryItem
                    key={item.slug}
                    title={item.tytul}
                    thumbnails={item.thumbnails[0].url}
                    slug={item.slug}
                    />
                </div>
            ))}
        </>
    )
}

const PaginatedItems = ({ itemsPerPage }) => {
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [result] = useQuery({query: GalleriesQuery});
    const { data, fetching, err } = result;

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(data?.galeries.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(data?.galeries.length / itemsPerPage));
    }, [itemOffset, data?.galeries, itemsPerPage]);

    const handlePageClick = (event) => {
        const newOffset = event.selected * itemsPerPage % data?.galeries.length;
        setItemOffset(newOffset);
    };

    if(fetching) return <p>Pobieram galerie...</p>
    if(err) return <p>O nie... {err.message}</p>

    return (
        <>
          <Items currentItems={currentItems} className={styles.items}/>
          <ReactPaginate
            nextLabel="następna >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="< poprzednia"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </>
      );
}

const Galleries = () => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });

    return(
        <>
        { isDesktopOrLaptop && <PaginatedItems itemsPerPage={24} /> }
        { isTabletOrMobile && <PaginatedItems itemsPerPage={9} /> }
        </> 
    );
}

export default Galleries;