const LOGO = [
    {
        id:1,
        image: '/Assets/logos/Logo_Parafia_2.png'
    },
    {
        id:2,
        image: '/Assets/logos/Logo_Parafia.png'
    },
    {
        id:3,
        image: '/Assets/logos/Nagłówek.png'
    },
    {
        id:4,
        image: '/Assets/logos/strona_ww.png'
    }
]

export default LOGO;