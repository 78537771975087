import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Header from './Components/Views/Parts/Header/Header/Header';
import Footer from './Components/Views/Parts/Footer/Footer/Footer';
import Content from './Components/Views/Parts/Content/Content/Content';
import { useMediaQuery } from 'react-responsive';

import './App.css';

function App() {

  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });

  return (
    <div className="App">
        <BrowserRouter>
            <Route path={"/"} render={() => <Header />} />
            <Route path={"/"} render={() => <Content/>} />
            {isDesktopOrLaptop && <Route path={"/"} render={() => <Footer/>} />}
        </BrowserRouter>
    </div>
  );
}

export default App;
