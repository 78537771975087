import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'urql';
import ImageGallery from 'react-image-gallery';
import ReactHtmlParser from 'react-html-parser';
import "react-image-gallery/styles/css/image-gallery.css";

const AboutBySlug = `
query($slug: String!){
    oParafiis(where:{slug: $slug}){
      tytul
      slug
      zawartosc{
        html
      }
      pictures {
        url
      }
      thumbnails{
        url
      }
      dodano
      createdBy{
        name
      }
    }
  } 
`;

const Parafia = (props) =>{

    const params = useParams();
    const slug = params.slug;

    const [result] = useQuery({
        query: AboutBySlug,
        variables: {
            slug
        }
    })

    const { data, fetching, err } = result;

    const [photosUrls, setPhotosUrl] = useState([]);    


    useEffect(() => {
          const array = data?.oParafiis[0].pictures?.map((obj, index) => {
            return {
              id: index,
              original: obj.url,
              thumbnail: data?.oParafiis[0].thumbnails[index]?.url
            }
          })
        setPhotosUrl(array)
    },[data])

    if(fetching) return <p>Pobieram dane dla wpisu</p>
    if(err) return <p>O nie... {err.message}</p>

    return(
      <>
        <div style={{marginTop:"10px", width:"100%", backgroundColor:"white", borderRadius:"11px"}}>
          <div style={{marginTop:"10px", display:"flex", justifyContent:"center", flexDirection:"column", alignItems:"center"}}>
            <h1 style={{marginBottom:"10px"}}>{data?.oParafiis[0]?.tytul}</h1>
            <span>
              {ReactHtmlParser(data?.oParafiis[0]?.zawartosc.html)}
            </span>
            {photosUrls?.length > 0 ? 
            <ImageGallery 
                style={{marginBottom:"10px"}}
                items={photosUrls}
                slideOnThumbnailOver={true}
            /> : 
              <p style={{marginBottom:"10px"}}>Brak zdjęć w galerii</p>
            }
            <span>Created by: {data?.oParafiis[0]?.createdBy.name} <br/>
                Created at: {data?.oParafiis[0]?.dodano}
            </span>
          </div>
        </div>
      </>
    );
}

export default Parafia;