import React from 'react';
import SideBarItem from '../../Parts/Content/SideBar/SideBarItem';

const Online = () => {

    return(
        <>
            <div style={{marginTop:"10px", width:"100%", backgroundColor:"white", borderRadius:"20px"}}>
                <div style={{marginTop:"10px", marginBottom:"20px"}}>
                    <SideBarItem iframe='<iframe title="" src="https://calendar.google.com/calendar/embed?showTabs=1&height=600&wkst=1&bgcolor=%23ffffff&ctz=Europe%2FWarsaw&src=MDZscnEyZWY3M3FxZWsxc3VrYTBlNzFzNjhAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%23616161&mode=AGENDA" width="100%" height="350" frameborder="0" scrolling="no"></iframe>'
                        title="Msze Święte" />
                    <div className="border-top my-2"></div>
                        <SideBarItem iframe='<iframe src="https://www.google.com/calendar/embed?showTabs=1&mode=AGENDA&height=600&wkst=1&bgcolor=%23FFFFFF&src=rgaockojes7e29m8tvr75q4ut4%40group.calendar.google.com&color=%23182C57ctz=Europe%2FWarsaw" scrolling="no" width="100%" height="350" frameBorder="0"></iframe>'
                                    title="Nabożeństwa" />
                    <div className="border-top my-2"></div>
                    <SideBarItem iframe='<iframe src="https://www.youtube.com/embed/yMxD3gdeXmk"
                                                            frameBorder="0"
                                                            width="100%"
                                                            height="250px"
                                                            borderRadius="20px"
                                                            allow="autoplay; encrypted-media"
                                                            allowFullScreen=""
                                                            title="video"
                                                            marginBottom="10px"
                                                            />'
                                            title="Live z Częstochowy" />                                        
                            
                    <div className="border-top my-2"></div>
                        <SideBarItem style={{marginBottom:"10px", borderRadius:"20px"}} iframe='<iframe width="100%" height="250" src="https://www.youtube.com/embed/ub6CMqmcnHo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />'
                                    title="Live z Fatimy" />
                    <div className="border-top my-2"></div>
                        <SideBarItem style={{marginBottom:"10px", borderRadius:"20px"}} iframe='<iframe width="100%" height="250" src="https://www.youtube.com/embed/Zz2lqOMiBeo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
                                    title="Live z Lourdes" />
                    <div className='border-top my-2'></div>
                        <SideBarItem style={{marginBottom:"10px", borderRadius:"20px"}} iframe='<iframe width="100%" height="250" src="https://www.youtube.com/embed/zXHPHl1-vmw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
                                    title="Live z Sanktuarium w Wilnie" />
                    <div className='border-top my-2'></div>
                        <SideBarItem style={{marginBottom:"10px", borderRadius:"20px"}} iframe='<iframe width="100%" height="250" src="https://www.youtube.com/embed/aRBdIrhNiEo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
                                    title="Live z Kaplicy Adoracji w Niepokalanowie" />  
                    <div className='border-top my-2'></div>
                        <SideBarItem style={{marginBottom:"10px", borderRadius:"20px"}} iframe='<iframe width="100%" height="250" src="https://www.youtube.com/embed/LSzlKvLZHFQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
                                    title="Live z Kaplica Cudownego Obrazu Kalwaria Zebrzydowska" />            
                    <div className='border-top my-2'></div>
                        <SideBarItem style={{marginBottom:"10px", borderRadius:"20px"}} iframe='<iframe width="100%" height="250" src="https://www.youtube.com/embed/jol6b_DXp3o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
                                    title="Live Różańcowy" />             
                </div>
            </div>
        </>
    );

}

export default Online;