import React from "react";
import { Link } from "react-router-dom";
import { NavLink, Card, CardImg} from "react-bootstrap";

const GalleryItem = ({thumbnails, slug, title}) => {

    return(
        <Card style={{marginTop:"10px", marginLeft:"20px"}}>
            <CardImg style={{width:"100%", height:"100%"}} variant="top" src={thumbnails}/>
            <Card body>
                <NavLink 
                    as={Link} 
                    to={{ pathname: "/galeria/"+slug, 
                            slug: slug }}
                >
                    {title}
                </NavLink> 
            </Card>                    
        </Card>
    );
}

export default GalleryItem;