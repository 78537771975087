import React from 'react';
import { useMediaQuery } from 'react-responsive';
import LOGO from '../../../../../logos';

const Footer = () => {

    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });

    const images = LOGO.map((item) => {return item.image});

    return(
        <footer style={{backgroundColor:"black", color:"white", display:"flex", justifyContent:"space-around"}}>
            {isDesktopOrLaptop && <img style={{width:"600px"}} src={images[3]} alt="Logo" />}
            {isTabletOrMobile && <img style={{width:"300px"}} src={images[3]} alt="Logo" />}
            <ul style={{listStyle:"none", marginTop:"auto" }}>
                <h3>Przydatne linki</h3>    
                <li><a href="https://diecezja.siedlce.pl/">Diecezja Siedlecka</a></li>
                <li><a href="https://wsd.siedlce.pl/">Wyższe Seminarium Duchowne diecezji Siedleckiej</a></li>
                <li><a href="http://radiopodlasie.pl/">Katolickie Radio Podlasie</a></li>
                <li><a href="https://muzeum.siedlce.pl/">Muzeum Diecezjalne</a></li>
                <li><a href="https://www.echokatolickie.pl/">Tygodnik "Echo Katolickie"</a></li>
                <li><a href="https://siedlce.caritas.pl/">Caritas Diecezji Siedleckiej</a></li>
            </ul>
        </footer>
    );
}

export default Footer;