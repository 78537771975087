import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ImageGallery from 'react-image-gallery';
import { useQuery } from "urql";
import "react-image-gallery/styles/css/image-gallery.css";

const GalleryBySlug = `
  query($slug: String!){
    galeries(where:{slug: $slug}){
      photos{
        url
      }
      thumbnails{
        url
      }
    }
  }
`;

const Gallery = (props) => {
    
    const params = useParams();
    const slug = params.slug;  
  
    const [result] = useQuery({
      query: GalleryBySlug,
      variables: {
        slug
      }
    })

    const { data, fetching, err } = result;   
    
    const [photosUrls, setPhotosUrls] = useState([]);

  useEffect(() => {
    if (data?.galeries[0]) {
      const array = data.galeries[0].photos.map((obj, index) => {
        return {
          id: index,
          original: obj.url,
          thumbnail: data.galeries[0].thumbnails[index].url
        }
      })
      setPhotosUrls(array)
    }
  }, [data])

    if(fetching) return <p>Pobieram zdjęcia do galerii...</p>
    if(err) return <p>O nie... {err.message}</p>
       
    return(
        <>     
          <div style={{marginTop:"10px", width:"100%", backgroundColor:"white", borderRadius:"11px"}}>
            <div style={{marginTop:"10px"}}>  
              {photosUrls.length > 0 &&    
                  <ImageGallery      
                    items={photosUrls}          
                    slideOnThumbnailOver={true}
                  />
              }
              </div>
          </div>
        </>
    );
}

export default Gallery;