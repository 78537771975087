import { React, useState, useEffect } from 'react';
import IntentionItem from './IntentionItem';
import { gql, useQuery } from 'urql';
import ReactPaginate from 'react-paginate';
import { useMediaQuery } from 'react-responsive';

const IntentionsQuery = gql`
{
    intencjes(orderBy: dodano_DESC){
      tytul
      slug
    }
  }
`;

const Items = ({currentItems}) => {
    return(
        <>
            {currentItems && currentItems.map((item) => (
                <div className='items' key={item.slug} style={{alignItems:"center", marginTop:"10px"}}>
                    <IntentionItem title={item.tytul} slug={item.slug}/>        
                </div>
            ))}
        </>
    );
}

const PaginatedItems = ({ itemsPerPage }) => {
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [result] = useQuery({query: IntentionsQuery});
    const { data, fetching, err } = result;

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(data?.intencjes.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(data?.intencjes.length / itemsPerPage));
    }, [itemOffset, data?.intencjes, itemsPerPage]);

    const handlePageClick = (event) => {
        const newOffset = event.selected * itemsPerPage % data?.intencjes.length;
        setItemOffset(newOffset);
    };

    if(fetching) return <p>Pobieram intencje...</p>;
    if(err) return <p>O nie... {err.message}</p>;

    return (
        <>
          <Items currentItems={currentItems} />
          <ReactPaginate
            nextLabel="następna >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="< poprzednia"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </>
      );
}

const Intentions = () => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });

    return(
        <>
            { isDesktopOrLaptop && <PaginatedItems itemsPerPage={21} /> }
            { isTabletOrMobile && <PaginatedItems itemsPerPage={9} /> }
        </> 
    );
}

export default Intentions;